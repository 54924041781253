import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {default as resourceTrad} from "../_models/translation.json";
import { UserProfileService } from "./user-profile.service";


@Injectable({
    providedIn: 'root'
  })
  export class TranslationService {

    currentLanguage: string =  "";
    constructor(private http: HttpClient, private userProfileService: UserProfileService) { 
    }

   getUserLanguage(){
      return this.userProfileService.getCurrentUserLanguage();
   }

  }