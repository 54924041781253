import { Component, OnInit } from '@angular/core';
import { Commons } from 'src/app/_helpers/commons';
import { SmagButtonService } from 'src/app/_services/SmagButtonService';
import { IdentityService } from 'src/app/_services/identity.service';
import { Identity } from '../../_models/authentification';
import { NotificationType } from 'src/app/_models/notification';
import { NotifierService } from 'src/app/_services/notifier.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { CLAIMS_KEY } from 'src/app/_helpers/routes';
import jwt_decode from "jwt-decode";
import { ApplicationService } from 'src/app/_services/application.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.scss']
})
export class AccueilComponent implements OnInit {

  public selected_id:string = "";
  public isChecked: boolean = false;
  public identities: Identity[] = [];
  public showBuffer: boolean = false;
  public bufferMessage: string = "";
  

  constructor(private common:Commons, 
    private route: ActivatedRoute,
    private accueilService: IdentityService, 
    public smagButtonSvc:SmagButtonService,
    private notifierService: NotifierService,
    private translateService: TranslateService,
    private authentificationService: AuthenticationService,
    private applicationService: ApplicationService) { }

    ticketId:string="";
    
  ngOnInit() {
    this.ticketId = this.route.snapshot.queryParamMap?.get('ticketId') ?? "";
    this.smagButtonSvc.loadSmagButtonLibraries();
    this.setApplication();
    this.common.initTranslationService();

   
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if(this.ticketId==""){
        this.translateService.get("MESSAGE.ERROR_MISSING_TICKET_ID").toPromise().then(trad =>
          {
              this.pushNotificationError(trad);
          });
      }
      this.translateService.get("MESSAGE.LOADING_MESSAGE").toPromise().then(trad =>
        {
          this.bufferMessage = trad;
        });
  
    });

  }
  handleChecked(evt : any){
    this.isChecked = !this.isChecked;
  }
  setApplication() {
    this.accueilService.getApplications()
      .subscribe((data: Identity[]) => this.identities = data)
  }
  pushNotificationError(message: string)
  {
    this.notifierService.push({message: message, type: NotificationType.error})
  }
  pushNotificationSuccess(message: string)
  {
    this.notifierService.push({message: message, type: NotificationType.success})
  }
  redirectToZendesk(){
    this.showBuffer = true;
    this.authentificationService.getZendeskToken().subscribe(redirectUrl => {
        localStorage.clear();
       window.location.href = redirectUrl.toString();
    },
    err => {
      this.showBuffer = false;
      this.translateService.get("MESSAGE.ERROR_MESSAGE").subscribe(trad => 
        this.pushNotificationError(trad)
      );
    });
  }
  showAPIResultError(err: any)
  {
    let errorCode="";
    if(err.error.message!=undefined)
      errorCode=err.error.message;
    else
      errorCode=err.error;

    if(errorCode.includes("ERROR_TOO_MANY_REQUEST"))
    {
      var timeRemaining = + errorCode.split("/")[1];
      timeRemaining = (timeRemaining < 60 && !0) ? 1 : (timeRemaining - (timeRemaining%60))/60;
      this.translateService.get("MESSAGE.ERROR_TOO_MANY_REQUEST").subscribe(trad => 
        this.pushNotificationError(trad + " (" + timeRemaining + ")")
      );
    }
    else{
      this.translateService.get(`MESSAGE.${errorCode}`).subscribe(trad => 
        typeof trad == "string" ? this.pushNotificationError(trad) : this.translateService.get("MESSAGE.ERROR_MESSAGE").subscribe(trad => this.pushNotificationError(trad))
      );
    }
    this.showBuffer = false;
  }
  createUrlToSend(refreshTokenResult: any, userEmail: string){
    var urlToSend = "";
    this.applicationService.getApplicationById(this.selected_id).then(result =>
      {
        result.subscribe((app: Identity) =>
        {
          urlToSend = `${app.callback_url}?token=${refreshTokenResult.Token}&refresh_token=${refreshTokenResult.Refresh_Token}`;
          this.addCommentToZendeskTicket(userEmail, urlToSend);
        });
      }, 
      err => {

      });
  }
  addCommentToZendeskTicket(userEmail: string, urlToSend: string)
  {
    this.authentificationService.addCommentZendeskTicket(
      this.identities.find((app: Identity) => app.application_id == this.selected_id)?.application_type ?? "",
      userEmail
      , urlToSend , this.ticketId).subscribe(res => {
        this.translateService.get("MESSAGE.SUCCESS_MESSAGE").subscribe(trad => 
          this.notifierService.push({message: trad, type: NotificationType.success})
          );
          this.showBuffer = false;
          this.redirectToZendesk();
      },
      err => {  
        this.showAPIResultError(err);
      });
  }
  validate() {
    if(this.ticketId!=""){
      this.accueilService.generate(this.selected_id, this.common.refresh_token)
      .subscribe(res => {
        this.showBuffer = true;
        //localStorage.setItem('token', res.Token);
        this.common.token=res.Token;
        this.smagButtonSvc.updateTokens(res.Token, res.Refresh_Token);
        const decodedToken: any = jwt_decode(res.Token);
        var userEmail = decodedToken[CLAIMS_KEY.EMAIL].toLowerCase();
        this.createUrlToSend(res, userEmail);
      },
      err => {
        this.showAPIResultError(err); 
      });
    }else{
      this.translateService.get("MESSAGE.ERROR_MISSING_TICKET_ID").subscribe(trad =>
        {
            this.pushNotificationError(trad);
        });
    }
  }
}

