declare var window: any;

//Decommenter pour tester en local
// window.env = {};
// window["env"]["API_URLS"] = {};
 
 export const environment = {
  name: window["env"]["name"] || 'validation',
  production: window["env"]["production"] || false,
  ApiManagerKey: window["env"]["ApiManagerKey"] || 'efe96169dfc1471cb6d64283f437faaf',
  appId: window["env"]["appId"] || 'e9920e47-639c-4677-8340-741197842f2c',
  SMAG_BUTTON_SCRIPT : window["env"]["SMAG_BUTTON_SCRIPT"]||'https://validation-smag-party-button-app-v1.azurewebsites.net/Scripts/SmagButton.js?',
  SMAG_BUTTON_CSS : window["env"]["SMAG_BUTTON_SCRIPT"]||'https://validation-smag-party-button-app-v1.azurewebsites.net/Content/SmagButton.css?',
  API_URLS: {
    smag_party_operational: window["env"]["API_URLS"]["smag_party_operational"] || 'https://validation-smag-application-api-management.azure-api.net/party-operational-extended/v1/',
    smag_authentication_operational: window["env"]["API_URLS"]["smag_authentication_operational"] || 'https://validation-smag-application-api-management.azure-api.net/authentication-operational-extended/v1/',
    smag_application_operational:  window["env"]["API_URLS"]["smag_application_operational"] || 'https://validation-smag-application-api-management.azure-api.net/application-operational-extended/v1/'
  }
};