import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateParser } from "@ngx-translate/core";
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { defaultTextDictionary } from "./messages";


export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/translations/', '.json');
}

export function translateHttpLoaderFactory(httpBackend: HttpBackend): TranslateHttpLoader {
  return new TranslateHttpLoader(new HttpClient(httpBackend), 'assets/translations/', '.json');
}

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
      let temporaryContextDict:any = defaultTextDictionary;
      for(let key of params.key.split('.'))
        temporaryContextDict = temporaryContextDict[key];
      return temporaryContextDict;
  }
}

export class CustomTranslationParser extends TranslateParser {

  public templateMatcher: RegExp = /{{\s?([^{}\s]*)\s?}}/g;

  interpolate(expr: string, params?: any): string {
    if(params && expr) {
      return expr.replace(this.templateMatcher, params.value)
    }
    return expr;
  }

  getValue (target: any, key: string): any {
    const [blockId, elementId] = key.split('.');
    if(!target || !target.ModulesTranslation) return;
    const blockTranslations = target.ModulesTranslation.find((moduleTranslation: { BlockId: string; }) => moduleTranslation.BlockId == blockId);
    if(!blockTranslations) return;
    const translations = blockTranslations.Translations;
    if(!translations) return;
    const foundTranslations = translations.find((element: { Code: string; }) => element.Code === elementId);
    if(!foundTranslations) return;
    return foundTranslations.Value;
  }
}