export const defaultTextDictionary = {
    INTERFACE: {
        VALIDATION_BUTTON : "Valider",
        SELECT_BAR_LABEL : "Sélectioner une application",
        VALIDATION_SENTENCE:"J'autorise Smag à utiliser mon compte Smag pour la durée de la résolution de mon ticket support.",
        EXPLANATION_SENTENCE:"Votre compte Smag sera utilisé uniquement à des fins du support technique Smag, aucune autre utilisation ne sera effectuée."
    },
    MESSAGE : {
        SUCCESS_MESSAGE : "Enregistrement effectué. Un email de confirmation vous a été envoyé",
        ERROR_MESSAGE : "L'enregistrement a échoué veuillez réessayer"
    }
}