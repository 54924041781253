import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpBackend } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './_components/login/login.component';
import { CallbackComponent } from './_components/callback/callback.component';
import { ErrorComponent } from './_components/error/error.component';
import { AccueilComponent } from './_components/accueil/accueil.component';
import { JwtInterceptor } from './_helpers/jwtinterceptor';
import { AuthGuard } from './_helpers/auth.guard';
import { ServerErrorInterceptor } from './_helpers/server-error-interceptor';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from '@angular/material/icon';
import { NotifierComponent } from './_components/notifier/notifier.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { CustomMissingTranslationHandler, CustomTranslationParser, translateHttpLoaderFactory } from './_helpers/translation-helper';
import { SmagBufferModule, ButtonModule } from "@smag-cloud/smag-components";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CallbackComponent,
    ErrorComponent,
    AccueilComponent,
    NotifierComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatSelectModule,
    BrowserAnimationsModule,
    FormsModule,
    MatCheckboxModule,
    MatIconModule,
    MatSnackBarModule,
    SmagBufferModule,
    ButtonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpBackend]
      },
      parser: {provide: TranslateParser, useClass: CustomTranslationParser},
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler}
    })
  ],
  providers: [
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
